body {
  font-family: sofia-pro, sans-serif;
}


.bd-grid-container {
  margin: 0 auto;
  max-width: 1440px;
}

.value-prop {
  //background: url("/pub/media/wysiwyg/fp-split-cropped.jpg");
  //background-position: center;
  //background-size: cover;
  //height: 600px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .overlay-wrap {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 100%;
    width: 50%;
  }
  .overlay {
    background: rgba(255, 255, 255, 0.8);
    text-align: center;
    width: 600px;
    padding: 2rem 2rem;
    //box-shadow: 0 2px 4px 0 rgba(50,50,93,.3);
    //border-radius: 15px;
    h2 {
      text-transform: uppercase;
      font-size: 3rem;
      color: #08435e;
      font-family: sofia-pro, sans-serif;
      font-weight: bold;
    }
    p {
      font-size: 1.5rem;
    }
    a {
      color: #D63230;
      font-size: 1.25rem;
      font-weight: bold;
      &.link-box {
        background: #fff;
        border: 2px solid #0a0a0a;
        color: #0a0a0a;
        display: inline-block;
        width: 175px;
        padding: .65rem 2rem;
        &:hover {
          background: #f7f7f7;
        }
      }
    }
    &.overlay-left {
      margin-left: 350px;
      background-color: transparent;
      box-shadow: none;
      margin-bottom: 100px;
      h2 {
        font-size: 2.25rem;
      }
      a {
        font-size: 2rem;
      }
    }
  }
}

.sale-ad {
  margin-top: 3rem;
  text-align: center;
}

.product-grid {
  margin: 3rem 0;
  text-align: center;
  h3, h1 {
    color: #08435e;
    font-family: sofia-pro, sans-serif;
    font-weight: bold;
    font-size: 1.9375rem;
  }
  .flex-container {
    flex-wrap: wrap;
    justify-content: center;
  }
  .product {
    padding: 1rem 2rem;
    transition: all 0.5s ease;
    width: 20%;
    transition: all 0.5s ease;
    .product-image {
      margin-bottom: 1rem;
      transition: all 0.5s ease;
      //background-color: white;
      border-radius: 200px;
      padding: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        max-width: 100px;
      }
    }
    .product-title {
      a {
        color: #333;
        font-family: sofia-pro, sans-serif;
        font-weight: 500;
        transition: all 0.25s ease;
        font-size: 1.25rem;
      }
    }
    &:hover {
      .product-image {
        opacity: .7;
        transform:translateY(-8px);
        //background-color: #f2f2f2;
      }
      .product-title {
        a {
          color: #2d76b8;
          text-decoration: underline;
        }
      }
    }
    &.custom {
      //background-color: #e6f3f5;
    }
  }
  &.featured {
    border-radius: 15px;
    margin: 0 auto;
    max-width: 1440px;
    padding: 2rem 0 3rem;
    &.valentines {
      background-color: #f7d4dc;
      a.button {
        background-color: #811831;
        &:hover {
          background-color: #d62951;
        }
      }
      .product-image {
        border-bottom: 1px solid #f7d4dc;
      }
    }
    &.sale {
      background-color: #e6f3f5;
      a.button {
        background-color: #5bb0be;
        &:hover {
          background-color: #337580;
        }
      }
      .product-image {
        border-bottom: 1px solid #e6f3f5;
      }
    }
    &.misc {
      //background-color: #eff7ee;
      //padding: 3rem 0;
      background-color: transparent;
      padding: 0;
      .product {
        margin-right: 2.5rem;
        border-radius: 0px;
        //padding: 1rem;
        &:last-of-type {
          margin-right: 0;
        }
        h4 {
          color: white;
          text-decoration: none;
          border-radius: 3px;
          padding: .5rem;
        }
        .product-image {
          border-bottom: 0px;
          padding: 1rem 0;
          img {
            max-width: 100%;
            border-radius: 3px;
          }
        }
        .product-title {
          //background-color: white;
          background: transparent;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 3px;
          a {
            font-size: 1.5rem;
          }
        }
        &.red {
          //background-color: #e41539;
          //border: 5px solid #e41539;
          h4 {
            background-color: #e41539;
          }
          .product-title {
            a {
              color: #e41539;
            }
            border: 5px solid #e41539;
          }
        }
        &.green {
          //background-color: #5fad56;
          //border: 5px solid #5fad56;
          h4 {
            background-color: #5fad56;
          }
          .product-title {
            a {
              color: #5fad56;
            }
            border: 5px solid #5fad56;
          }
        }
        &.blue {
          //background-color: #2d76b8;
          //border: 5px solid #2d76b8;
          h4 {
            background-color: #2d76b8;
          }
          .product-title {
            a {
              color: #2d76b8;
            }
            border: 5px solid #2d76b8;
          }
        }
        &.orange {
          //background-color: #fe843d;
          //border: 5px solid #fe843d;
          h4 {
            background-color: #fe843d;
          }
          .product-title {
            a {
              color: #fe843d;
            }
            border: 5px solid #fe843d;
          }
        }
      }
    }
    .product {
      background-color: white;
      border-radius: 15px;
      margin-right: .75rem;
      padding: 0;
      .product-image {
        padding: 2rem 2rem 1rem;
        border-radius: 0px;
        display: flex;
        background-color: transparent;
      }
      .product-title {
        padding: 1rem 2rem 2rem 2rem;
      }
      &:hover {
        transform: translateY(-8px);
        .product-image {
          transform: translateY(0px);
        }
      }
      h4 {
        color: #337580;
        font-weight: bold;
        margin-top: 1.5rem;
      }
    }
    a.button {
      font-size: 1rem;
      font-weight: bold;
      margin: 1rem 0 2rem;
      padding: 1rem 2rem;
      border-radius: 25px;
      transition: all 0.5s ease;
    }
    .price {
      background-color: #F8333C;
      color: $white;
      font-weight: bold;
      width: 70%;
      margin-left: 15%;
      padding: .5rem .25rem;
      border-radius: 5px;
    }
  }
}

.split-box {
  display: flex;
  justify-content: center;
  .split-element {
    transition: all 0.5s ease;
    &:hover {
      box-shadow: 0 2px 4px 0 rgba(50,50,93,.3);
      cursor: pointer;
      opacity: .7;
    }
  }
  .split-left {
    margin-right: 3%;
  }
  .split-right {
    .split-right-upper {
      margin-bottom: 5%;
    }
    .split-right-bottom {
      display: flex;
      .marg-right {
        margin-right: 3%;
      }
    }
  }
}

.product-grid-custom-wrap {
  border-left: 2px solid #2d76b8;
  width: 40%;
  .product {
    width: 50%;
  }
}

.product-grid-basic-wrap {
  width: 60%;
  .product {
    width: 33%;
  }
}

@media screen and (max-width: 39.9375em) {
  .product-grid-basic-wrap {
    width: 100%;
    .product {
      width: 50%;
    }
  }
  .product-grid-custom-wrap {
    width: 100%;
    padding: 0;
    border-top: 2px solid #2d76b8;
    border-left: 0px;
    margin-top: 1rem;
  }
  .split-box {
    display: block;
    text-align: center;
    .split-left {
      margin-right: 0;
    }
    .split-right {
      margin-top: 1rem;
      text-align: center;
      .marg-right {
        margin-right: 0;
        margin-bottom: 1rem;
      }
    }
  }
  .product-grid.misc {
    .flex-container {
      display: block;
      .product {
        width: 100%;
        margin-bottom: 2.5rem;
        margin-right: 0;
        .product-title {
          padding: 2rem;
          a {
            font-size: 1.1rem;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}