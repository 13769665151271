.footer {
  background-color: #f7f7f7;
  margin-top: 3rem;
  padding: 30px 50px;
  text-align: left;
}

.footer-grid {
  display: flex;
  justify-content: center;
}

.footer-block {
  margin-right: 3rem;
  &:last-of-type {
    margin-right: 0;
  }
  .h4 {
    color: #08435e;
    font-family: sofia-pro, sans-serif;
    font-size: 1.3rem;
    font-weight: bold;
  }
  ul {
    margin-left: 0;
    li {
      list-style: none;
      margin-bottom: 0;
      a {
        color: #333;
        font-size: .9rem;
      }
    }
  }
  img {
    margin-top: 2rem;
    max-width: 125px;
  }
  i {
    color: #08435e;
    font-size: 1.1rem;
  }
  p {
    font-size: .9rem;
    font-weight: bold;
    margin-bottom: .25rem;
    line-height: 1.2;
  }
  .ssl {
    text-align: right;
  }
  .em-wrapper-social {
    margin-top: 1rem;
    a {
      background-color: #2d76b8;
      color: white;
      border-radius: 100px;
      font-size: 1.25rem;
      padding: .5rem .5rem;
      margin-right: .5rem;
      transition: all 0.5s ease;
      &:hover {
        background-color: #08435e;
      }
    }
  }
}

@media screen and (max-width: 39.9375em) {
  .footer {
    padding: 30px 1rem;
    .footer-grid {
      display: block;
    }
  }
}