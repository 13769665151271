#vl-main-wrapper {
  padding: 0!important;
}

.header-bd {
  background-color: #2d76b8;
  width: 100%;
  .header-interior {
    margin: 0 auto;
    max-width: 1440px;
    .logo {
      padding: 0 0 0 1rem;
      max-width: 100%;
      margin: 0;
      width: 100%;
    }
    .header-contact {
      padding: .5rem 1rem 0 0;
      text-align: right;
      p {
        color: $white;
        font-size: .8rem;
        margin: 0;
      }
      a {
        color: $white;
        padding-right: 1rem
      }
      i {
        font-size: .7rem;
      }
    }
    .header-search {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 3%;
      position: relative;
      input {
        width: 100%;
        height: 36px;
        border: 3px solid
        #98c2e6;
        border-radius: 15px;
        padding: 0 1rem;
        &::placeholder {
          color: #666;
        }
      }
      .search-link {
        position: absolute;
        right: 2.9rem;
        background: #f7f7f7;
        border-radius: 15px;
        padding: .15rem .25rem;
        color: #08435e;
      }
    }
    .header-login {
      display: flex;
      justify-content: center;
      align-items: center;
      a {
        color: $white;
        padding-right: 1.5rem;
      }
    }
    .header-menu {
      z-index: 2000;
      nav {
        ul.dropdown {
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          width: 100%;
          margin: 0 0 .5rem 0;
          li {
            list-style-type: none;
            margin-bottom: 0;
            a {
              color: $white;
              font-weight: bold;
              position: relative;
            }
          }
        }
      }
    }
  }
  .discount-banner {
    background-color: #5fad56;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: .5rem;
    position: relative;
    z-index: auto;
    margin-bottom: 0;
    p {
      color: $white;
      margin: 0;
      font-weight: bold;
    }
  }
}

.fixed {
  box-shadow: 0 2px 4px 0 rgba(50,50,93,.3);
  position: fixed;
  z-index: 100;
  .header-interior {
    max-width: 1500px;
    padding: 0 1rem;
    .grid-x {
      position: relative;
    }
  }
  .header-logo {
    padding: .25rem 0;
    img {
      max-height: 45px;
    }
  }
  .header-contact {
    display: none;
  }
  .header-search {
    max-width: 300px;
  }
  .header-login {
    position: absolute;
    right: 0;
    height: 100%;
  }
  .header-menu {
    max-width: 700px;
    display: flex;
    align-items: center;
    nav {
      ul {
        margin-bottom: 0!important;
        li {
          a {
            font-size: .9rem;
            padding: 0 1rem;
          }
        }
      }
    }
  }
  .discount-banner {
    p {
      font-size: .9rem;
    }
  }
}

.dropdown-menu {
  width: 100%;
  margin: 0;
  margin-top: 0px;
  padding: 2rem;
  margin-top: -2.6rem;
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(50, 50, 93, 0.3);
  border-top: 10px solid #f9e153;
  display: none;
  transition: all 0.5s ease;
  position: absolute;
  z-index: 1000;
  .nav-flex {
    display: flex;
    justify-content: center;
    .col-3 {
      padding: 0 2rem;
      width: auto;
      .nav-title-link {
        font-weight: bold;
        color: #08435e;
        font-size: 17px;
        margin-bottom: .25rem;
        border-bottom: 1px solid #2d76b8;
        padding-bottom: .25rem;
        a {
          color: #08435e;
        }
      }
      ul.ls-link {
        margin: 0;
        li {
          list-style-type: none;
          margin-bottom: 0;
          a {
            color: #333;
            font-size: 14px!important;
          }
        }
      }
      img {
        margin-top: .5rem;
      }
    }
  }
  &.open {
    display: block;
  }
  &.open-on {
    display: block;
  }
  ul.ls-link.foil-mylar li a {
    font-size: inherit!important;
  }
}

.mobile-menu-nav {
  display: none;
}

.dropdown-li {
  &:hover {
    a {
      &.notch {
        &::after {
          content: '';
          position: absolute;
          left: 35%;
          top: 100%;
          width: 0;
          height: 0;
          border-left: 20px solid transparent;
          border-right: 20px solid transparent;
          border-top: 20px solid #2d76b8;
          clear: both;
        }
      }
    }
  }
}

.mobile-menu-toggle {
  font-size: 1.5rem;
  color: #2d76b8;
}

.mobile-menu {
  background: white;
  display: none;
  box-shadow: 0 2px 4px 0 rgba(50, 50, 93, 0.3);
  position: absolute;
  width: 100%;
  max-height: 425px;
  overflow-y: scroll;
  .menu {
    display: block;
    .toplevel-li {
      .toplevel-link {
        font-size: 1.2rem;
        font-weight: bold;
        padding: 1rem;
        color: #2d76b8;
        &::before {
          content: "";
        }
        .toggle-icon {
          font-size: .8rem;
        }
      }
      margin-bottom: 0;
      border-bottom: 1px solid #2d76b8;
    }
    .sub-div {
      display: none;
      padding: 0 1rem;
      .nav-title-link {
        text-decoration: underline;
        margin-bottom: .5rem;
      }
      ul {
        margin-left: 0;
        li {
          list-style-type: none;
          margin-bottom: 0;
          a {
            color: #2d76b8;
            padding: 0 0 .5rem 0;
            font-size: inherit !important;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 39.9375em) {
  .header-bd .header-interior .header-contact p {
    line-break: anywhere;
  }
  .header-bd .header-interior .header-contact a {
    padding-right: .75rem;
  }
  .header-bd .discount-banner {
    display: none;
  }
  .bd-menu {
    display: none;
  }
  .menu-icon::after {
    background: #08435e;
    box-shadow: 0 7px 0 #08435e, 0 14px 0 #08435e;
  }
  .header-bd {
    .header-interior {
      .header-contact {
        margin: .25rem 0 .5rem 0;
        padding: 0 1rem;
        text-align: center;
        i {
          display: none;
        }
        a {
          font-size: .75rem;
        }
      }
      .logo {
        margin-bottom: .5rem;
        padding: 0;
        text-align: center;
        width: 100%;
        img {
          display: inline;
          max-width: 250px;
        }
      }
      .header-menu {
        margin-top: .75rem;
        nav {
          ul.dropdown {
            display: none;
            margin-bottom: 0;
            li {
              background-color: #08435e;
            }
          }
        }
        .mobile-menu-nav {
          display: block;
          padding: .4rem .5rem .5rem .5rem;
          text-align: right;
          background-color: #f9e153;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .mobile-login {
            margin-right: 1rem;
          }
        }
      }
    }
  }
  .fixed {
    .header-interior {
      padding: 0;
      .logo {
        margin: .25rem 0;
      }
      .header-search {
        max-width: 100%;
      }
      .mobile-menu-nav {
        width: 100%;
      }
    }
  }
  .fixed {
    position: relative;
  }
  .header-bd .header-interior .header-search .search-link {
    right: 1rem;
  }
}

.header-phone-number {
  white-space: nowrap;
}

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .header-bd {
    .header-interior {
      .header-contact {
        text-align: center;
      }
      .header-logo {
        text-align: center;
        .logo {
          padding: 1rem 0 .5rem;
          width: 100%;
          img {
            max-width: 250px;
            padding-left: 1rem;
            width: 100%;
          }
        }
      }
      .header-menu {
        margin-bottom: 1rem;
        width: 100%;
        text-align: center;
        .menu {
          width: 100%;
        }
      }
    }
    .discount-banner {
      text-align: center;
    }
    &.fixed {
      .header-login {
        position: relative;
        height: auto;
      }
    }
  }
  .header-bd .header-interior .header-menu nav ul.dropdown {
    margin: 0;
  }
  .product-grid .product .product-title a {
    font-size: 1rem!important;
  }
  .split-right-bottom {
    display: flex;
  }
  .product-grid.misc {
    .product {
      margin: 0 3% !important;
      width: 44%;
    }
  }
  .footer-grid {
    flex-wrap: wrap;
    .footer-block {
      margin: 0;
      width: 33%;
    }
  }
  .header-bd .header-interior .header-search .search-link {
    right: 2rem;
  }
}

@media screen and (min-width: 40em) and (max-width: 1000px) {
  .dropdown-menu {
    .col-3:last-of-type {
      display: none;
    }
  }
  #accessories-menu {
    .col-3:last-of-type {
      display: block;
    }
  }
}

@media screen and (min-width: 63.9375em) and (max-width: 1025px) {
  .header-bd .header-interior .header-contact {
    margin-bottom: .5rem;
  }
  .product-grid.featured.misc .product h4 {
    font-size: 1.25rem;
  }
  .discount-banner {
    text-align: center;
  }
}

@media screen and (min-width: 63.9375em) and (max-width: 1200px) {
  .header-bd .header-interior .header-contact {
   margin-bottom: .5rem;
  }
  .product-grid.featured.misc .product h4 {
    font-size: 1.25rem;
  }
  .discount-banner {
    text-align: center;
  }
}

@media screen and (min-width: 800px) and (max-width: 1450px) {
  .fixed {
    padding-top: .5rem;
  }
  .fixed .header-menu {
    margin-bottom: 1rem;
    margin-top: .5rem;
    max-width: 100%;
    .menu {
      width: 100%;
    }
  }
  .fixed .header-search {
    max-width: inherit;
  }
  .fixed .header-login {
    position: relative;
    height: inherit;
  }
}

@media screen and (min-width: 63.9375em) and (max-width: 1725px) {
  #foil-dropdown {
    .col-3:last-of-type {
      display: none;
    }
  }
}

.mobile-menu {
  .nav-title-link {
    a {
      color: #333;
      padding: .75rem 0 .5rem 0;
    }
  }
}